import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Title from '../../MOLECULES/TEXT/TITLE/Title';

const ContainerWithoutSidebar = () => {
  const location = useLocation();

  if (location.pathname === '/association/home' || location.pathname === '/home') {
    return (
      <div className="main-contents pt-[15px]">
        <Outlet />
      </div>
    );
  }

  return <Outlet />;
};

export default ContainerWithoutSidebar;
