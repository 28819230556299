import { Button, Input, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import blockAtom from '../../../recoil/atom/login/blockAtom'
import { FormattedMessage } from 'react-intl'
import Footer from '../../HOME/Components/Footer'

const pwd = '02877#@!'

const Temporary = () => {
  const nav = useNavigate()

  const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

  return (
    <>
      <div className='bg-[#000] h-[100vh] flex justify-center items-center'>
        <div className='w-[350px] h-auto bg-[#fff] rounded-[15px] px-[20px] pt-[30px] pb-[40px]'>
          <div className='flex flex-col items-center gap-[30px]'>
            <img
              src={`${s3URL}/asset/images/logo/TKW_LOGO.png`}
              className='w-[200px]'
              alt=''
            />
            <Typography className='text-[20px] text-[#222] leading-[36px] font-semibold text-center'>
              <FormattedMessage id='서비스 점검중 입니다.' />
              <br />
              {'오전 07:00 ~ 오전 10:00'}
              {/* <FormattedMessage id='불편을 드려 죄송합니다.' /> */}
              <br />
            </Typography>
            <Typography className='text-[16px] text-[#222] leading-[36px] font-semibold text-center'>
              (<FormattedMessage id='이메일' />) info@sportsent.org <br />
              (<FormattedMessage id='전화' />) 02-975-1359
            </Typography>
            <Button
              variant='outlined'
              size='small'
              onClick={() => nav('/')}
            >
              <FormattedMessage id='홈' />
            </Button>
            {/* <div className='flex flex-col items-center gap-[20px]'>
              <div className='flex justify-center w-[300px] gap-[20px] items-end text-[#888888]'>
                <Input
                  type='password'
                  className='px-[10px]'
                  value={v}
                  onChange={(e) => setV(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
              
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Temporary
