import React from 'react'
import { CircularProgress, Button as MUIButton } from '@mui/material'

const ButtonStyle = {
  border: '1px solid #000',
  borderRadius: '5px',
  color: '#000',
  fontWeight: '500',
  padding: '6px 10px',
  boxShadow: '0',
  '&.MuiButton-root': {
    '&:hover, &:active': {
      backgroundColor: '#f2f2f2',
      border: '1px solid #000',
      boxShadow: '0',
    },
  },
  // 기본으로 적용되는 스타일
  '&.MuiButton-outlined': {
    backgroundColor: '#fff',
  },

  // contained
  '&.MuiButton-contained': {
    color: '#fff',
    border: '0 none',
    '&:hover, &:active': {
      backgroundColor: '#6c81ee',
      border: '0 none',
      boxShadow: '0',
    },
  },
}

const Button = React.forwardRef(
  (
    {
      variant,
      loading,
      className,
      sx,
      minWidth,
      minHeight,
      width,
      height,
      fontSize,
      startIcon,
      endIcon,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <MUIButton
        sx={{
          minWidth: minWidth || 'auto',
          minHeight: minHeight || 'auto',
          width: width || 'auto',
          height: height || '40px',
          fontSize: fontSize || '14px',
          ...ButtonStyle,
          ...sx,
        }}
        className={className}
        ref={ref}
        variant={variant ?? 'outlined'}
        disabled={loading}
        startIcon={startIcon}
        endIcon={endIcon}
        {...props}
      >
        {loading ? (
          <span className='flex items-center justify-center'>
            <CircularProgress
              size={20}
              color='inherit'
            />
          </span>
        ) : (
          children
        )}
      </MUIButton>
    )
  },
)

Button.displayName = 'Button'

export default Button
