import React from 'react'
import Typography from '../../../ATOM/TEXT/Typography'

const DeleteText = ({ onClick, end, center, mt, text, disabled, color, className }) => {
  return (
    <div
      className={`text-[#777777] flex ${end ? 'justify-end' : center ? 'justify-center' : 'justify-start'} ${
        className ? className : ''
      }`}
    >
      <Typography
        className={`underline underline-offset-[6px] ${
          color
            ? `text-[${color}] decoration-[${color}]`
            : 'text-[var(--color-font-grey)] decoration-[var(--color-font-grey)]'
        } cursor-pointer ${mt ? `mt-[${mt}]` : 'mt-0'}`}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </Typography>
    </div>
  )
}

export default DeleteText
