import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'block',
  storage: sessionStorage,
});

const blockAtom = atom({
    key: 'block',
    default: false,
    effects_UNSTABLE: [persistAtom],
});

export default blockAtom;