import { Navigate, Outlet } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import blockAtom from '../../../recoil/atom/login/blockAtom'

const BlockRoutes = ({ role }) => {
  const block = useRecoilValue(blockAtom)
  // return <Navigate to='/service/temporary' />
  return false ? <Navigate to='/service/temporary' /> : <Outlet />
}

export default BlockRoutes
